const deepmerge = require('deepmerge');

const letters = "ABCDEF";

// The scoring tracks for each of the three rounds.
const rounds = [[[2, 3], [2, 1], [2, 1], [2, 1], [1, 1]],
                [[2, 5], [2, 1], [2, 1], [2, 1], [1, 1]],
                [[2, 4], [3, 2], [3, 2], [2, 2], [2, 2]]];


// This is the global state that is kept in persistent storage.
const initialGlobal = {
  round: 1,           // 1, 2, 3. 4 means "game over"
  turn: 0,            // Used to identify the answers
  track: rounds[0],   // The scoring track for the current round.

  questionValue: 1,  // 1, 2, 3, 4
  reversed : false,   // Are we looking for wrong answer?
  doubled : false,    // Are bonus points doubled?

  // Message (in HTML) sent to all players
  globalMessage : "Waiting for all players to arrive",

  state: "Start",     // "Start", "WaitQuestion", "WaitAnswers", "GameOver"
};

// Lexicographic compare of two arrays, returning (positive, 0, or negative).
// The arrays are expected to be the same length.
function arrayCompare(a, b) {
  for (const i in a) {
    const delta = a[i] - b[i];
    if (delta !== 0) {
      return delta;
    }
  }
  return 0;
}

// Converts encoded to a six-character boolean string.
function toBinary(answers) {
  return answers.toString(2).padStart(letters.length, '0'); 
}

// Returns the number of one bits in a 32-bit integer.
// Source https://graphics.stanford.edu/~seander/bithacks.html#CountBitsSet64
function countOnes(v) {
  v = v - ((v >> 1) & 0x55555555);
  v = (v & 0x33333333) + ((v >> 2) & 0x33333333);
  return ((v + (v >> 4) & 0xF0F0F0F) * 0x1010101) >> 24; 
}

function pluralize(n, things) {
  if (n === 0) return `no ${things}s`;
  if (n === 1) return `one ${things}`;
  if (n === 2) return `two ${things}s`;
  if (n === 3) return `three ${things}s`;
  if (n === 4) return `four ${things}s`;
  if (n === 5) return `five ${things}s`;
  if (n === 6) return `six ${things}s`;
  return `${n} ${things}s`;
}

// players is the source of truth for which players are in the game,
// and their latest guess.
// global.scoreboard is the source of truth for the scores and positions.
// The two objects are not updated atomically. 
// Merges the current players into the scoreboard, making sure that the
// set of players in the scoreboard matches players, and all fields are
// given default values if missing.
function syncPlayers(global, players) {
  if (!("scoreboard" in global)) {
    global.scoreboard = {};
  }
  Object.assign(global.scoreboard, deepmerge(global.scoreboard, players));
  Object.entries(global.scoreboard).forEach(e => {
    const [name, info] = e;
    if (name in players) {
      // Supply defaults for newly added players.
      if (!("score" in info)) {
        info.score = 0;
      }
      if (!("position" in info)) {
        info.position = 0;
      }
      if (!("answers" in info)) {
        info.answers = 0;
      }
      delete info.turn;  // Not needed in scoreboard.
    } else {
      // Delete players who have left the game from the scoreboard.
      delete global.scoreboard[name];
    }
  });
};

// Here is what happens when the gamemaster reveals the right answer.
// All the player's guesses are checked, adjusting their score.
// If the round ends, we advance to the next round if there is one.
function gameScoreAnswers(correctAnswers, global, players) {
  syncPlayers(global, players);

  // Produce an array we can iterate over.
  const playerData = Object.entries(global.scoreboard);

  // Build a table of score values acheived for advancing to each square.
  var scoreValue = [];
  global.track.forEach(t => {
    scoreValue.push(...Array(t[0]-1).fill(0));
    scoreValue.push(t[1]);
  });

  // Determine which answers players are going for.
  if (global.reversed) {
    correctAnswers = (~correctAnswers) & 0b111111;
  }

  const checkAnswers = (guess) => {
    const wrong = ~correctAnswers & guess;
    const numRight = countOnes(guess & ~wrong);
    return ({wrong: wrong, numRight: numRight});
  };

  var roundOver = false;
  playerData.forEach(e => {
    const info = e[1];
    const numRight = checkAnswers(info.answers).numRight;

    if (numRight > 0) {
      // Award bonus points.
      var bonus = numRight - 1;
      if (global.doubled) {
        bonus *= 2;
      }
      info.score += bonus;

      // Advance position.
      var pos = info.position;
      for (var i = 0; i < global.questionValue; ++i) {
        if (pos < scoreValue.length - 1) {
          pos += 1;
  	info.score += scoreValue[pos];
        }
      }
      info.position = pos;
      if (pos === scoreValue.length - 1) {
        roundOver = true;
      }
    }
  });

  // Advance to the next round if somebody reached the end of the track.
  var gameOver = false;
  if (roundOver) {
    const round = ++global.round;
    if (round - 1 < rounds.length) {
      global.track = rounds[round - 1];
      playerData.forEach(e => {
        e[1].position = 0;
      });
    } else {
      gameOver = true;
    }
  }

  // Figure out who, if anybody, just won.
  var winners = new Set();
  if (gameOver) {
    var winningScore = [-1, -1];
    playerData.forEach(e => {
      const [name, info] = e;
      const thisScore = [info.score, info.position];
      const delta = arrayCompare(thisScore, winningScore);
      if (delta > 0) {
	winningScore = thisScore;
	winners.clear();
      }
      if (delta >= 0) {
        winners.add(name);
      }
    });
  }

  // Delete the global message and create an individualized message
  // for each player.
  global.globalMessage = {missing: true};
  playerData.forEach(e => {
    const [name, info] = e;
    const {wrong, numRight} = checkAnswers(info.answers);
    var bonusPrefix = null;
    var message;
    if (numRight === 2) {
      bonusPrefix = "You doubled up";
    } else if (numRight === 3) {
      bonusPrefix = "You went all-in";
    }
    if (bonusPrefix !== null) {
      var bonusPoints = numRight - 1;
      if (global.doubled) {
        bonusPoints *= 2;
      }
      message = `${bonusPrefix} for ${
                 pluralize(bonusPoints, "bonus point")}.<br>You advanced ${
		 pluralize(global.questionValue, "square")}.`
    } else if (numRight >= 1) {
      message = `You advanced ${pluralize(global.questionValue, "square")
                 } for your correct answer.`;
    } else {
      var wrongAnswers = [];
      const binary = toBinary(wrong);
      for (const i in binary) {
        if (binary.charAt(i) === "1") {
	  wrongAnswers.push(letters.charAt(i));
        }
      }
      if (wrongAnswers.length === 0) {
	// If a player enters after the all the guesses have been displayed,
	// but before scoring has begun.
        message = "You did not guess";
      } else if (wrongAnswers.length === 1) {
        message = `Answer ${wrongAnswers[0]} is incorrect.`;
      } else if (wrongAnswers.length === 2) {
        message = `Answers ${wrongAnswers[0]} and ${
	           wrongAnswers[1]} are incorrect.`;
      } else if (wrongAnswers.length === 3) {
        message = `Answers ${wrongAnswers[0]}, ${wrongAnswers[1]
	           } and ${wrongAnswers[2]} are incorrect.`
      } else {
	// More than three wrong answers?
        message = "Hmm. Not sure what happened.";
      }
    }
    if (gameOver) {
      if (winners.has(name)) {
        if (winners.size === 1) {
	  message += "<br>Congratulations, you won!";
        } else {
	  message += "<br>Congratulations, you tied for first place!";
	}
      } else {
        message += "<br>The game is over.";
      }
    } else if (roundOver) {
      const round = global.round;
      message += `<br>Beginning round ${
        round === 2 ? "two" : round === 3 ? "three" : round
      }.`
    }
    info.message = message;
  });
  global.state = "WaitQuestion";
}

export { rounds, initialGlobal, arrayCompare, countOnes,
         letters, gameScoreAnswers, toBinary };
