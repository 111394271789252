import React from 'react';
import ReactDOM from 'react-dom';
import app from 'firebase/app';
import firebase from 'firebase/app';
import './index.css';
import { initialGlobal, arrayCompare, countOnes, letters,
         gameScoreAnswers, toBinary } from './game.js';

require('firebase/auth');
require('firebase/database');
const deepmerge = require('deepmerge');

// Compares the contents of two arrays.
// Is this really not built into JavaScript?
function equalArrays(lhs, rhs) {
  if (lhs.length !== rhs.length) {
    return false;
  }
  return lhs.reduce((acc, val, index) => acc && val === rhs[index],  true);
}

// We record whether the user has been validated by storing playerSecurity
// or gameMasterSecurity in session storage at securityKey. This is probably
// horribly unsecure, but this was as much work as I was willing to put in.
const securityKey = "half-life-60435-security-key";

// This is the used to store the player name.
const playerKey ="half-life-60435-player-key"; 

// I don't think we need to worry about storage keys conflicting with keys
// from other apps running the browser. OTOH, there seems little advantage
// in making them short.

// The name we use to find our Firebase app
const appName = "half-truth";

const playerPassword = new Int32Array([
  50177285, 569562437, 1500715475, -2050225174,
 -577551643, 325950546, 1430589450, -191165396]);
const playerSecurity = "$0mlfzb8y$"

const gameMasterPassword = new Int32Array([
  -1525834175, 2065300007, 331221152, 1760177456,
  1668344730, 872421452, 1108911198, -976733588]);
const gameMasterSecurity = "$KW1aXBQf$"


const initialPlayer = {
  turn: 0,      // Which turn the answer applies to.
  answers: 0,   // binary encoding of six bits
  message: "Waiting..."
};

var databaseHandle = null;

function globalRef() { return databaseHandle.ref("/global"); }
function playersRef() { return databaseHandle.ref("/players"); }

function databaseInitialize() {
  globalRef().set(initialGlobal,
    (error) => {
     if (error !== null) {
       window.alert("Write failed: " + error.message);
     }
  });
  playersRef().remove(
    (error) => {
     if (error !== null) {
       window.alert("Write failed: " + error.message);
     }
  });
}

function globalTransaction(update) {
  globalRef().transaction(update,
    (error, committed, snapshot) => {
     console.log("Transaction committed " + committed);
     if (error !== null) {
       window.alert("Transaction failed: " + error.message);
     }
  });
}

function playerTransaction(name, update) {
  const ref = databaseHandle.ref("/players/" + name);
  ref.transaction(update,
    (error, committed, snapshot) => {
     console.log("Transaction committed " + committed);
     if (error !== null) {
       window.alert("Transaction failed: " + error.message);
     }
  });
}

// Escapes characters that cause trouble in HTML.
// Stolen from Stack Overflow.
function htmlEscape(str) {
  return str
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}



// The input is a array of [name, encoded-ans] pairs.
// The output is an HTML table.
function guessTable(players) {
  players.sort(
    (a, b) => a[0].localeCompare(b[0], 'en', {sensitivity: 'accent'}));

  return players.reduce((prefix, pair) => {
    var output = prefix + `<tr><td>${htmlEscape(pair[0])}</td>`;
    const binary = toBinary(pair[1].answers);
    for (const i in binary) {
      output += `<td style="text-align: center">${
                binary.charAt(i) === "0" ? "&bull;" : letters.charAt(i)
                }</td>
`
    }
    output += '</tr>\n'
    return output;
  },
  "<table>\n<caption>Player Guesses</caption>\n<tbody>\n") + 
  "</body>\n</table>";
}

// Game logic. Should this go in a different file?
function gameNextQuestion(global) {
  const die = Math.floor(Math.random() * 6);
  const questionValue = [1, 2, 3, 4, 2, 1][die];
  const reversed = die === 4;
  const doubled = die === 5;
  const squareCount = ["one square", "two squares",
                       "three squares", "four squares"][questionValue - 1];
  var rules = `Choose up to three answers<br>If all answers are ${
    reversed ? "wrong" : "right"}, you will advance ${squareCount}.`;
  if (doubled) {
    rules += `<br>Bonus points are doubled.`
  }
  if (reversed) {
    rules += `<br>Remember, you are trying for \
<span style="color:red;">wrong</span> answers.`;
  }
  globalTransaction(current => {
    if (current.state !== "WaitAnswers") {
      current.questionValue = questionValue;
      current.reversed = reversed;
      current.doubled = doubled;
      current.globalMessage = rules;
      current.state = "WaitAnswers"
      current.turn += 1;
      return current;
    }});
}

function gameAnswers(turn, pressed) {
  playerTransaction(sessionStorage.getItem(playerKey),
                    (current) => { current.turn = turn;
		                   current.answers = pressed;
				   return current;
				 });  
}


// Lexicographically compare two arrays. The arrays must be of the same
// length and contain numbers. Returns positive, zero, negative to
// indicate >, ==, <.
// Wrapper around the JavaScript case-independent string compare.
function stringCompare(a, b) {
  return a.localeCompare(b, 'en', {sensitivity: 'accent'});
}

// A variation of the above for displying the scoreboard.
function mergePlayers(global, players) {
  var merged = Object.entries(deepmerge(global.scoreboard, players));
  merged.forEach(e => {
    const [name, info] = e;
    if (name in players) {
      // Supply defaults for newly added players.
      if (!("score" in info)) {
        info.score = 0;
      }
      if (!("position" in info)) {
        info.position = 0;
      }
      if (!("turn" in info)) {
        info.turn = 0;
      }
      delete info.answers;  // Not needed for Scoreboard display.
    } else {
      // Delete players who have left the game.
      delete global.scoreboard[name];
    }
  });

  merged.sort((a, b) => {
    // Sort by high score, high position, name.
    const delta = arrayCompare([-a[1].score, -a[1].position],
                               [-b[1].score, -b[1].position]);
    return delta === 0 ? stringCompare(a[0], b[0]) : delta;
  });  

  return merged;
};


class Buttons extends React.Component {
  constructor(props) {
    super(props)
    this.state = {pressed: Array(6).fill(false)}
  }
  button_color(i) {
    return this.state.pressed[i] ? "#4CAF50" : "#ECF0F1" 
  }
  clickButton(i) {
     let p = [...this.state.pressed]
     p[i] = ! p[i]
     // Do not allow more than three buttons activated.
     if (p.reduce((total, elem) => total + elem, 0) > 3) {
       return;
     }
     this.setState({pressed: p})    
  }
  renderButton(i) {
    const letter = letters.charAt(i);
    return (<button
               style={{width: "60px", height: "60px", border:"solid",
                       marginTop: "1px",
                       marginBottom: "1px",
                       marginLeft: "1px",
                       marginRight: "1px",
                       fontSize: "20px",
                       backgroundColor: this.button_color(i)}}
               onClick={() => {this.clickButton(i)}}>
            {letter}
            </button>);
  }
  clickSubmit() {
    // Convert pressed button to binary, where low-order bit represents F.
    const pressed = this.state.pressed.reduce(
      (encoded, bit) => 2 * encoded + bit, 0);

    // Count the number of buttons pressed.
    const numPressed = countOnes(pressed);
 
    console.log("Pressed " + numPressed);

    if (this.props.numButtonsOk(numPressed)) {
      this.setState({pressed: Array(6).fill(false)});
      this.props.submitButtons(pressed);
    }
  }
  render() {
    // This button layout matches that of the board game.
    // Changing it confused some players.
    return (
      <div style={{marginLeft: "50px"}}>
      <div style={{height:"20px"}} />
      {this.renderButton(0)}
      {this.renderButton(5)}<br />
      {this.renderButton(1)}
      {this.renderButton(4)}<br />
      {this.renderButton(2)}
      {this.renderButton(3)}<br />
      <div style={{height:"10px"}} />
      <button style={{width: "122px", height:"40px",
                      marginLeft: "1px", marginRight: "1px",
                      border: "solid"}}
          onClick={() => {this.clickSubmit()}}>
      Submit Answers
      </button>
      </div>
    );
  }
}

// Used with dangerouslySetInnerHTML
function htmlWrapper(wrapped) {
  return {__html: wrapped};
}

class ScoreBoard extends React.Component {
  computeDimensions() {
    const players = mergePlayers(this.props.global, this.props.players);
    this.players = players;

    // Parameters of the drawing. Sadly, they are font-specific.
    // It would be nice if we could compute them, from available APIs,
    // but I could not get that to work. For example, textBaseline
    // appears to work differently on Safari vs Chrome.
    this.spacing = 18;
    this.yFudge = 12;  // Needed to get text to line up with lines.
    this.gap = 27;

    this.rows = players.length; 
    this.track = this.props.global.track;
    this.columns = this.track.reduce((total, val) => total + val[0], 0)
    this.font = "14px Noto Sans TC";

    // Before we can create the canvas we are drawing on, first need
    // to compute its dimensions. For that we need to measure some text.
    // For that we will need a canvas. We can't draw on this one.
    const canvas = document.createElement("CANVAS");

    const ctx = canvas.getContext('2d');
    ctx.font = this.font;


    // The width of the widest player.
    this.name_max_width =
      players.reduce((max, item) => 
        Math.max(max, ctx.measureText(item[0]).width), 0);

    // The width of the widest score.
    this.score_max_width =
      players.reduce((max, item) => 
        Math.max(max, ctx.measureText(item[1].score).width), 0);

    // Compute the starting points for the columns.
    this.name_x = 0
    this.score_x = this.name_x + this.name_max_width + this.gap;
    this.scoreboard_x = this.score_x + this.score_max_width + this.gap;
    this.canvas_width = this.scoreboard_x + this.columns * this.spacing;

    // Compute the starting points for the rows.
    this.title_y = 0
    this.header_y = this.title_y + this.spacing;
    this.scoreboard_y = this.header_y + this.spacing;
    this.canvas_height = this.scoreboard_y + this.rows * this.spacing;
  }

  componentDidMount() {
    this.updateCanvas();
  }
  componentDidUpdate() {
    this.updateCanvas();
  }

  drawText(ctx, txt, highlight, x, y) {
    // lets save current state as we make a lot of changes        
    ctx.save();

    // get width of text
    const width = ctx.measureText(txt).width;

    if (highlight) {
      // color for background
      ctx.fillStyle = "#4CAF50"
      // draw background rect assuming height of font
      ctx.fillRect(x, y, width, parseInt(ctx.font, 10));
    }
    
    // text color
    ctx.fillStyle = "#000000"

    // draw text on top
    ctx.fillText(txt, x, y + this.yFudge);
    
    // restore original state
    ctx.restore();

    return width;
  }

  updateCanvas() {
    const ctx = this.refs.canvas.getContext('2d');

    // Erase the results of the previous update.
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    ctx.fillstyle = "#000000"
    // ctx.textBaseline = 'top';
    ctx.textAlign = "left";
    ctx.font = this.font;

    const title = ["Round One", "Round Two", "Round Three", "Final Scores"]
                  [this.props.global.round - 1];
    ctx.fillText(title, this.scoreboard_x, this.title_y + this.yFudge);
 
    const players = this.players;
    const turn = this.props.global.turn;
 
    // Just kept tweaking the fudge factor until the font lined up.
    const text_y = this.scoreboard_y + 2;

    let y = text_y;
    let x = this.name_x;
    players.forEach((item) => {
      const name = item[0];
      this.drawText(ctx, name, turn !== 0 && item[1].turn === turn, x, y);
      y += this.spacing 
    });
    x += this.name_max_width + this.gap;

    y = text_y;
    const xxx = x + this.score_max_width/2;
    ctx.textAlign = "center";
    players.forEach((item) => {
      const score = item[1].score.toString();
      this.drawText(ctx, score, false, xxx, y);
      y += this.spacing 
    });
    ctx.fillText("Score", xxx, this.header_y + this.yFudge);

    y = this.scoreboard_y;
    const x0 = this.scoreboard_x;
    const x1 = this.canvas_width;
    const num = players.length + 1;
    for (var i = 0; i < num; i++) {
      ctx.beginPath();
      ctx.moveTo(x0, y);
      ctx.lineTo(x1, y);
      ctx.stroke();
      y += this.spacing;
    }
 
    // Output the vertical lines.
    // Figure out which ones should be solid.
    // solid maps a scoring column to the number of points awarded
    // when that column is reached.
    let solid = new Map();
    let col = 0;
    solid.set(col, null);
    this.track.forEach((segment) => {
      col += segment[0];  // Number of columns in the segment.
      solid.set(col, segment[1]);  // Point value of the segment.
    });
 
    // The endpoints of the vertical lines.
    const y0 = this.scoreboard_y;
    const y1 = this.canvas_height;
    x = this.scoreboard_x;

    // This loop runs columns+1 times because fencepost.
    for (i=0; i <= this.columns; ++i) {
      ctx.beginPath();
      if (solid.has(i)) {
        // Output a solid line.
        ctx.setLineDash([]);
        const point_value = solid.get(i);
        if (point_value !== null) {
          ctx.textAlign = "center";
          ctx.fillText(point_value, x - this.spacing/2,
                       this.header_y + this.yFudge);
        }
      } else {
        // Output a dashed line.
        ctx.setLineDash([4, 4])
      }
      ctx.moveTo(x, y0);
      ctx.lineTo(x, y1);
      ctx.stroke();
      x += this.spacing;
    }

   const radius = this.spacing * .3;
   y = y0 + this.spacing / 2;
   for (i = 0; i < this.rows; ++i) {
    ctx.beginPath();
    ctx.arc(x0 + this.spacing/2 + players[i][1].position*this.spacing,
            y, radius, 0, 2*Math.PI)
    ctx.fill()
    ctx.stroke();
    y += this.spacing;
   }
  }
  render() {
    this.computeDimensions();
    // Another place where theory differs from practice. Perfoming pixel
    // calculataions in floating point doesn't really work, so we add in
    // some slop to avoid having the drawing truncated.
    const c = (<canvas ref="canvas"
                       width = {this.canvas_width + 2 }
                       height = {this.canvas_height + 2} />);
    return c;
  }
}

function setupFirebase(setState, listeners) {
  const fbApp = app.initializeApp({
      apiKey: "AIzaSyB4o1HHHDrMaev9uh9iGPOfBKLwLdjXpnY",
      authDomain: "half-truth-60435.firebaseapp.com",
      databaseURL: "https://half-truth-60435-default-rtdb.firebaseio.com",
      projectId: "half-truth-60435",
      storageBucket: "half-truth-60435.appspot.com",
      messagingSenderId: "675233468847",
      appId: "1:675233468847:web:5c129c591c7e868a715f5a",
      measurementId: "G-P7D9E0DD3D"}, appName);
  fbApp.auth().signInAnonymously()
    .then(() => {
      console.log("Firebase login successful");

      // Revoke authorization when the browser tab is closed.
      fbApp.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .catch((error) => {
        setState({foo: error});
      });
 
      databaseHandle = fbApp.database();

      const ref = playersRef();
      ref.on("child_added", listeners.childAdded,
        (error) => {console.log("child_added");
                    setState({foo: error})});
      ref.on("child_changed", listeners.childChanged,
        (error) => {console.log("child_changed");
                    setState({foo: error})});
      ref.on("child_removed", listeners.childRemoved,
        (error) => {console.log("child_removed");
                    setState({foo: error})});
      globalRef().on("value", listeners.replaceGlobal,
        (error) => {console.log("global_value");
                    setState({foo: error})});

      setState({});
    })
    .catch((error) => {
      console.log("Exception");
      setState({foo: error})
    });
}
 
class Master extends React.Component {
  constructor(props) {
    super(props)

    this.revealAnswers = this.revealAnswers.bind(this);
    this.resetGame = this.resetGame.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);

    this.state = null;
  }

  componentDidMount() {
    setupFirebase(this.setState.bind(this), this.props.listeners)
  }

  componentDidUpdate() {
    if (this.props.global.state === "WaitAnswers") {
      const turn = this.props.global.turn;
      const players = Object.entries(this.props.players);

      // See if all the players have given answers.
      if (players.reduce((acc, cur) => acc && cur[1].turn === turn, true)) {
        globalTransaction(current => {
          // Reveal the guesses to the players.
          current.globalMessage = guessTable(players);

	  // Reveal the Submit Answers buttons.
          current.state = "WaitReveal";

 	  return current;
        });
      }
    }
  }

  revealAnswers(buttonsPressed) {
    console.log("reveal Answers", this.props.players);
    globalTransaction(current => {
      if (current.state !== "revealAnswers") {
        console.log("callback", this.props.players);
        gameScoreAnswers(buttonsPressed, current, this.props.players);
        return current;
      }});
  }

  resetGame() {
    if (window.confirm("Press OK to reset game")) {
      databaseInitialize();
    } 
  }
  nextQuestion() {
    gameNextQuestion(this.props.global);
                   // We generate the random number in the Gamemaster client
                   // To make test scripts easier.
  }

  playerColor(value) {
    const turn = this.props.global.turn;
    if (turn === 0 || turn !== value[1].turn) {
      return({});
    }
    return ({backgroundColor: "#4CAF50"});  // green
  }

  render() {
    if (this.state === null) {
      return null;
    }
    if ("foo" in this.state) {
      return (<div>
              Server Error
	      <div>
	      {JSON.stringify(this.state.foo)}
	      </div>
	      </div>);
    }

    const centered = {textAlign: "center"};
    const player_scores = mergePlayers(
          this.props.global, this.props.players).map((value) => {
      const name = value[0];
      const score = value[1].score;

      return(<tr key={name}>
             <td style={this.playerColor(value)}>{name}</td>
             <td style={centered}>{score}</td>
             <td><button onClick = {()=>{this.props.api(
                   "delete", {player: name})}}>
             Kick
             </button></td>
             </tr>);
    });
    const optionalButtons = []
    const state = this.props.global.state;
    if (state === "Start") {
      optionalButtons.push(
        <button key={0} onClick={this.nextQuestion} >
        Start Game 
        </button>);
    } else if (state === "WaitReveal") {
      optionalButtons.push(
        <div key={0}>
        <h2>Reveal the three correct answers and press Submit</h2>
        <Buttons numButtonsOk={n => n === 3} 
                 submitButtons={this.revealAnswers} />
        </div>
      );
    } else if (state === "WaitQuestion") {
      optionalButtons.push(
        <button key={0} onClick={this.nextQuestion} >
        Next Question
        </button>);
    } else if (state === "GameOver") {
      optionalButtons.push(
        <div key={0}>
        <div style={{height: "10px"}} />
        <span>Game Over:</span>
        <button style={{marginLeft: "10px"}}
                onClick = {()=>{this.props.api("restart", {})}}>
        Play Again 
        </button>
        <button style={{marginLeft: "10px"}}
                onClick = {()=>{this.props.api("reset", {})}}>
        Delete Game 
        </button>
        </div>);
    }
    return (<div>
            <h1>Game Master Console</h1>
            <table>
            <tbody>
            <tr>
            <th style={{textAlign: "left"}}>Player</th>
            <th>Score</th>
            <th></th>
            </tr>
            {player_scores}
            </tbody>
            </table>
            {optionalButtons}
            <div style={{height:"20px"}} />
            <hr />
            <button onClick = {()=>{this.resetGame();}}>
            Reset Game 
            </button>
            </div>);
  }
}

class ReconnectButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {selected: null}

    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({selected: event.target.value});
  }
  handleSubmit(event) {
    if (this.state.selected !== null) {
      this.props.setPlayer(this.state.selected);
    }
    event.preventDefault();
  }

  render() {
    const allPlayers = Object.entries(this.props.players)
                       .map(p => p[0]).sort(stringCompare)
                       .map(p => 
      <div key={p}>
      <label>
      <input type="radio" value={p}
             onChange={event=>this.handleChange(event)}
             checked={this.state.selected === p} />

      {p}
      </label>
      </div>);
    
    return (
      <form onSubmit={event => this.handleSubmit(event)}>
      {allPlayers}
      <button type="submit">Reconnect</button>
      </form>);
  }
}

// Component used to get the player's name.
class GetPlayerName extends React.Component {
  constructor(props) {
    super(props)
    this.state = {textInput: "",  errorMessage: ""}

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setPlayer = this.setPlayer.bind(this);
  }
 
  // Establishes the player name.
  setPlayer(name) {
    playerTransaction(name, current => {
      if (current == null) { return initialPlayer; }});
    this.props.setPlayerName(name);
  }

  render() {
    const marginStyle = {margin:"0", marginLeft: "20px"};
    const separator = {height: "15px"};

    return (
      <div>
        <span>Welcome to the Half Truth game server.</span>
        <div style={separator} />
        <span>If you are a new player, enter your name:</span>
        <form style={marginStyle} onSubmit={this.handleSubmit}>
          <label>
            <input type="text"
             value={this.state.textInput}
             onChange={this.handleChange} />
          </label>
          <input type="submit" value="Submit" />
          { this.state.errorMessage !== "" &&
          <div style={{color: "red"}}>
          {this.state.errorMessage}
          </div>
          }
        </form>
      {Object.keys(this.props.players).length > 0 &&
         <div>
         <div style={separator} />
         <span>If you were disconnected,
               click your name and press Reconnect:</span>
           <div style={marginStyle}>
           <ReconnectButton players={this.props.players} 
                            setPlayer={this.setPlayer} />
           </div>
         </div>
      }
      </div>
    );
  }
  handleChange(event) {
    const name = event.target.value;
    const message = name.length > 20 ? "Name limit 20 chararacters" : "";
    this.setState({textInput: event.target.value,
                   errorMessage: message})
  }
  handleSubmit(event) {
    const sanitized = this.state.textInput.trim();
    if (this.state.errorMessage === "" && sanitized.length > 0) {
      this.setPlayer(sanitized);
    }
    event.preventDefault();
  }
}

class Player extends React.Component {
  constructor(props) {
    super(props)
    this.state = null;
    this.setPlayerName = this.setPlayerName.bind(this); 
  }
  
  componentDidMount() {
    setupFirebase(this.setState.bind(this), this.props.listeners)
  }

  setPlayerName(name) {
    sessionStorage.setItem(playerKey, name); 
    this.forceUpdate();   // because player name is not part of state.
  }

  leaveGame() {
    if (this.props.global.state !== "GameOver") {
      if (!window.confirm("Press OK to leave the game. " +
                          "If you return, your score will be zero.")) {
        return;
      }
    }
  }

  render() {
    if (this.state === null) {
      return null;
    }
    if ("foo" in this.state) {
      return (<div>
              Server Error
	      <div>
	      {JSON.stringify(this.state.foo)}
	      </div>
	      </div>);
    }

    const playerName = sessionStorage.getItem(playerKey);
    if (playerName == null) {
      return <GetPlayerName players={this.props.players}
                            setPlayerName={this.setPlayerName} />
    }

    // Wait for the listeners to populate the state.
    if (!("state" in this.props.global)) {
      return null;
    }
    if (!(playerName in this.props.players)) {
      return null;
    }

    var html_message;
    {
      const g = this.props.global;
      console.log("rendering player message");
      console.log(g.globalMessage);
      if (typeof g.globalMessage === "string") {
        html_message = g.globalMessage;
      } else if (playerName in g.scoreboard) {
        html_message = g.scoreboard[playerName].message;
	if (html_message === undefined) {
	  html_message = "Waiting..."; 
	}
      }
    }
    
    // I don't really understand how to properly set the dimensions of
    // a container. This code seems to work okay. It may not handle weird
    // corner cases correctly.
    const numPlayers = Object.keys(this.props.players).length;
    const estMinHeight = Math.max(100, 35 + 25 * numPlayers);
    // Render the main page.
    return (
      <div className="game">
        <h2>{playerName}</h2>
        <div style={{width: "330px",
                     border:"2px solid black", padding:"10px"}}>
        <div style={{minHeight: estMinHeight + "px"}}
             dangerouslySetInnerHTML={htmlWrapper(html_message)} />
        </div>
        { this.props.global.state === "WaitAnswers" &&
          <Buttons numButtonsOk={n => 1 <= n && n <= 3} 
              submitButtons={gameAnswers.bind(this, this.props.global.turn)} />
        }
        {numPlayers > 0 &&
          <div>
          <div style={{height:"20px"}}></div>
          <ScoreBoard global={this.props.global}
	              players={this.props.players} />
          </div>
        }
        <div style={{height:"20px"}}></div>
        <button onClick={()=>{this.leaveGame()}}>Leave Game</button>
      </div>
    );
  }
}

class GetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {textInput: "", errorState: 0}
    this.encoder = new TextEncoder();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <label>
            Password:
            <input type="password"
                   autoComplete="password"
                   value={this.state.textInput}
                   onChange={this.handleChange} />
          </label>
          <input type="submit" value="Submit" />
          { this.state.errorState !== 0 &&
            <div style={{color: "red"}}>
            {this.state.errorState === 1 ? " Password incorrect" : " Try again"}
            </div>
          }
        </form>
      </div>
    );
  }
  handleChange(event) {
    this.setState({textInput: event.target.value})
  }
  
  checkPassword(password) {
    crypto.subtle.digest('SHA-256', this.encoder.encode(password))
    .then((digest)=>{
      const answer = new Int32Array(digest);
      if (equalArrays(answer, playerPassword)) {
        this.props.changeSecurityKey(playerSecurity);
      } else if (equalArrays(answer, gameMasterPassword)) {
        this.props.changeSecurityKey(gameMasterSecurity);
      } else {
        this.setState({textInput: "",
                       errorState: this.state.errorState === 1 ? 2 : 1});
      }
    });
  }
  
  handleSubmit(event) {
    const sanitized = this.state.textInput.trim();
    this.checkPassword(sanitized);
    event.preventDefault();
  }
}
 
class Game extends React.Component {
  constructor(props) {
    super(props)

    // This is the state of the game, as recorded in the database.
    // Only the game master writes global; changes are propagated to
    // the players. Anyone can modify players; changes are propagated
    // to everyone.  global maps onto the root of the database. players
    // is a Javascript objects whose keys match the subkeys of the database.
    // The keys are base64 representations of the player name. The two
    // objects in state are never replaced, so it is safe to pass them
    // downward as props.
    this.state = {global: {}, players: {}};

    this.changeSecurityKey = this.changeSecurityKey.bind(this);
    this.listeners = {childAdded: this.childAdded.bind(this),
                      childChanged: this.childChanged.bind(this),
                      childRemoved: this.childRemoved.bind(this),
		      replaceGlobal: this.replaceGlobal.bind(this)};
  }

  // Updates the security key. Calls forceUpdate afterwards because the
  // value of the security key is not part of the state React tracks.
  changeSecurityKey(value) {
    sessionStorage.setItem(securityKey, value); 
    this.forceUpdate();
  }

  childAdded(snapshot, prevChildKey) {
    console.log("Child added " + snapshot.key);
    this.setState((prevState, props) => {
      prevState.players[snapshot.key] = snapshot.val();
      return prevState;
    });
  }
  childChanged(snapshot) {
    console.log("Child changed " + snapshot.key);
    this.setState((prevState, props) => {
      prevState.players[snapshot.key] = snapshot.val();
      return prevState;
    });
  }
  childRemoved(snapshot) {
    console.log("Child removed " + snapshot.key);
    this.setState((prevState, props) => {
      delete prevState.players[snapshot.key];
      return prevState;
    });
  }
  replaceGlobal(snapshot) {
    console.log("Replace global");
    console.log(snapshot.val());
    this.setState((prevState, props) => {
      Object.assign(prevState.global, snapshot.val());
      prevState.global.scoreboard = snapshot.val().scoreboard;
      return prevState;
    });
  }

  render() {
    const secured = sessionStorage.getItem(securityKey);
    if (secured === null) {
      return <GetPassword changeSecurityKey={this.changeSecurityKey} />
    }
    if (secured === playerSecurity) {
      return (<Player global={this.state.global}
                      players={this.state.players}
		      listeners={this.listeners} />);
    } else if (secured === gameMasterSecurity) {
      return (<Master global={this.state.global}
                      players={this.state.players}
		      listeners={this.listeners} />);
    } else {
      // WTF?
      console.log("Unexpected role " + secured);
      sessionStorage.setItem(securityKey, null);
      return null;
    }
  }
};

ReactDOM.render(
  <Game />,
  document.getElementById('root')
);
